/**
 * Labstep
 *
 * @module components/Entity/Action/Update
 * @desc An action component for updating an entity
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import ActionComponent from 'labstep-web/core/Action/Component';
import EntityCanUpdateContainer from 'labstep-web/containers/Entity/Update/Can';
import {
  IEntityActionUpdateContainerProps,
  IEntityActionUpdateProps,
} from './types';

export const EntityActionUpdate: React.FC<
  IEntityActionUpdateProps
> = ({
  status,
  body,
  update,
  options,
  actionComponentProps,
  optimistic,
}) => (
  <ActionComponent
    type="option"
    onClick={(additionalOptions): void =>
      update(body, enhanceOptions({ options, ...additionalOptions }))
    }
    status={optimistic ? undefined : status}
    dataTestId="entity-action-update"
    {...actionComponentProps}
  />
);

export const EntityActionUpdateContainer: React.FC<
  IEntityActionUpdateContainerProps
> = ({
  entityName,
  id,
  optimistic,
  actionComponentProps,
  ...rest
}) => {
  const renderIfNoEditPermissions =
    typeof actionComponentProps === 'function';
  return (
    <EntityCanUpdateContainer
      entityName={entityName}
      id={id}
      optimistic={optimistic}
      renderIfNoEditPermissions={renderIfNoEditPermissions}
    >
      {({ status, update, canEdit }): React.ReactElement => (
        <EntityActionUpdate
          update={update}
          status={status}
          optimistic={optimistic}
          actionComponentProps={
            renderIfNoEditPermissions
              ? actionComponentProps({ canEdit })
              : actionComponentProps
          }
          {...rest}
        />
      )}
    </EntityCanUpdateContainer>
  );
};

export default EntityActionUpdateContainer;
