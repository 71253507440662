/**
 * Labstep
 *
 */

import React from 'react';
import { generateNewDateString } from 'labstep-web/services/date.service';
import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ProtocolValueActionUpdateAmountDeductedAtModal from './Modal';
import { getHeaderAndMessage } from './Modal/utils';
import { IProtocolValueActionUpdateAmountDeductedAtProps } from './types';
import { updateAmountDeductedAtActionComponentProps } from './constants';

export const ProtocolValueActionUpdateAmountDeductedAt: React.FC<
  IProtocolValueActionUpdateAmountDeductedAtProps
> = ({ protocolValue }) => {
  const canEdit = useHasAccess(
    protocolValue.entityName,
    protocolValue.guid,
    Action.edit,
  );
  if (protocolValue.is_output) {
    return null;
  }
  if (protocolValue.amount_deducted_at) {
    return null;
  }
  if (!(protocolValue.resourceItem && canEdit)) {
    return null;
  }
  if (getHeaderAndMessage(protocolValue).header) {
    return (
      <ProtocolValueActionUpdateAmountDeductedAtModal
        protocolValue={protocolValue}
      />
    );
  }
  return (
    <EntityActionUpdate
      entityName={protocolValue.entityName}
      id={protocolValue.guid}
      body={{
        amount_deducted_at: generateNewDateString(),
      }}
      actionComponentProps={
        updateAmountDeductedAtActionComponentProps
      }
    />
  );
};

export default ProtocolValueActionUpdateAmountDeductedAt;
