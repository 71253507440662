/**
 * Labstep
 *
 * @module models/organization-plan
 * @desc Typescript export class for OrganizationPlan
 */

import { currencies } from 'labstep-web/constants/currencies';
import { Entity } from 'labstep-web/models/entity.model';

export class OrganizationPlan extends Entity {
  static readonly entityName = 'organization_plan';

  get entityName(): string {
    return OrganizationPlan.entityName;
  }

  constructor(data: Partial<OrganizationPlan>) {
    super();
    Object.assign(this, data);
  }

  id: number;

  amount: number;

  currency: string;

  interval: string;

  quantity: number;

  upcoming_invoice_date: string;

  upcoming_invoice_total: number;

  group_count: number;

  payment_brand: string;

  payment_last4: string;

  payment_exp_month: number;

  payment_exp_year: number;

  discount_percent_off: number;

  get currencyConstant() {
    if (currencies[this.currency.toUpperCase()]) {
      return currencies[this.currency.toUpperCase()];
    }

    return null;
  }

  get currencyDisplay() {
    return this.currencyConstant ? this.currencyConstant.symbol : '';
  }

  get amountDisplay() {
    return this.currencyConstant
      ? (
          this.amount /
          10 ** this.currencyConstant.decimal_digits
        ).toFixed(this.currencyConstant.decimal_digits)
      : '';
  }

  get upcomingTotalDisplay() {
    return this.currencyConstant
      ? (
          this.upcoming_invoice_total /
          10 ** this.currencyConstant.decimal_digits
        ).toFixed(this.currencyConstant.decimal_digits)
      : '';
  }
}
