/**
 * Labstep
 *
 * @module components/PermissionRole/Action/UpdateDropdownPill
 * @desc Dropdown showing permissions and allowing to update on click
 */

import EntityActionUpdateDropdownPill from 'labstep-web/components/Entity/Action/Update/DropdownPill';
import { IEntityActionUpdateDropdownPillProps } from 'labstep-web/components/Entity/Action/Update/DropdownPill/types';
import { DEFAULT_PERMISSION_ROLES } from 'labstep-web/constants/roles';
import { PermissionRole } from 'labstep-web/models/permission-role.model';
import { selectEntitiesByArray } from 'labstep-web/state/selectors/entity';
import React from 'react';
import { useSelector } from 'react-redux';
import { IPermissionRoleActionUpdateDropdownPillProps } from './types';

export const PermissionRoleActionUpdateDropdownPill: React.FC<
  IPermissionRoleActionUpdateDropdownPillProps
> = ({ entity, currentValue, body, disabled, withOwner = true }) => {
  const additionalPermissionRoles: PermissionRole[] = useSelector(
    (state: any) => {
      return selectEntitiesByArray(
        state,
        'permission_role',
        Object.keys(state.entities.permission_role.byId),
      );
    },
  );

  const defaultPermissionRoles = withOwner
    ? DEFAULT_PERMISSION_ROLES
    : [DEFAULT_PERMISSION_ROLES[1], DEFAULT_PERMISSION_ROLES[2]];
  const items: IEntityActionUpdateDropdownPillProps['items'] = [
    ...defaultPermissionRoles.slice().reverse(),
    ...additionalPermissionRoles,
  ].map((permissionRole) => ({
    label: permissionRole.name,
    body: body(new PermissionRole(permissionRole)),
  }));
  return (
    <EntityActionUpdateDropdownPill
      currentValue={currentValue}
      disabled={disabled}
      entity={entity}
      items={items}
    />
  );
};

export default PermissionRoleActionUpdateDropdownPill;
