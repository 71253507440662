/**
 * Labstep
 *
 *
 * @desc Edit action for protocol timer
 */

import React from 'react';
import { enhanceOptions } from 'labstep-web/services/utils.service';
import Modal from 'labstep-web/core/Modal/Default';
import EntityActionEdit from 'labstep-web/components/Entity/Action/Edit';
import EditTimerForm from 'labstep-web/components/ProtocolTimer/Form/Edit';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ProtocolTimer } from 'labstep-web/models/protocol-timer.model';
import { IProtocolTimerActionEditProps } from './types';

const ProtocolTimerActionEdit: React.FC<
  IProtocolTimerActionEditProps
> = ({ protocolTimer, viewComponent, options }) => {
  const canEdit = useHasAccess(
    protocolTimer.entityName,
    protocolTimer.guid,
    Action.edit,
  );
  return (
    <Modal
      header={`Edit ${ProtocolTimer.getHumanReadableEntityName(
        false,
        true,
      )}`}
      viewComponent={({ toggleModal }) => {
        const onClick = canEdit ? toggleModal : undefined;
        if (viewComponent) {
          return viewComponent({ toggleModal: onClick });
        }
        return (
          <EntityActionEdit
            entity={protocolTimer}
            actionComponentProps={{
              type: 'option',
              text: 'Edit',
              icon: 'pencil',
              onClick,
            }}
          />
        );
      }}
      content={({ toggleModal }) => (
        <EditTimerForm
          protocolTimer={protocolTimer}
          options={enhanceOptions({
            options,
            onSuccess: toggleModal,
          })}
        />
      )}
    />
  );
};

export default ProtocolTimerActionEdit;
