/**
 * Labstep
 *
 * @module components/ProtocolValue/Form/ShowEdit/ResourceItemOutput
 * @desc Edit resource item output
 */

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import ResourceItemActionCreateProtocolValue from 'labstep-web/components/ResourceItem/Action/CreateProtocolValue';
import ResourceItemPreview from 'labstep-web/components/ResourceItem/Preview';
import SearchSelectModal from 'labstep-web/core/Select/Search/Modal';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { updateEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import React from 'react';
import { getSearchProps } from '../Input/utils';
import columnDefs from '../colDefs';
import { IProtocolValueFormShowEditResourceItemOutputProps } from './types';

export const getOnChange =
  (protocolValue: ProtocolValue) =>
  (option: ResourceItem): void => {
    const selectedOption = option ? option.id : null;

    let entityId = selectedOption;
    let body: Record<string, unknown> = {
      protocol_value_origin_guid: protocolValue.guid,
    };

    // if resource item cleared
    if (!selectedOption && protocolValue.resource_item_output) {
      entityId = protocolValue.resource_item_output.id;
      body = { protocol_value_origin_guid: null };
    }

    store.dispatch(
      updateEntity(ResourceItem.entityName, entityId!, body, {
        optimistic: true,
      }),
    );
  };

export const ProtocolValueFormShowEditResourceItemOutput: React.FC<
  IProtocolValueFormShowEditResourceItemOutputProps
> = ({ protocolValue, modalProps }) => {
  const canEdit = useHasAccess(
    protocolValue.entityName,
    protocolValue.guid,
    Action.edit,
  );

  const createBody = {
    amount: protocolValue.amount,
    unit: protocolValue.unit,
  };

  const searchProps = getSearchProps(protocolValue, true);

  const props = {
    entityName: ResourceItem.entityName,
    hasValue: !!protocolValue.resource_item_output,
    onChange: getOnChange(protocolValue),
    canEdit,
    children: protocolValue.resource_item_output ? (
      <ResourceItemPreview
        resourceItem={protocolValue.resource_item_output}
        showIcon
        showResource
      />
    ) : null,
    createAction: (
      <ResourceItemActionCreateProtocolValue
        protocolValue={protocolValue}
        actionComponentProps={{
          type: 'button',
          text: 'New Item',
        }}
      />
    ),
    creatable:
      protocolValue.resource?.allowed_actions.includes('*') ||
      protocolValue.resource?.allowed_actions.includes(
        'protocol_value:edit',
      ),
    createBody: protocolValue.resource
      ? { ...createBody, resource_id: protocolValue.resource.id }
      : createBody,
    searchProps,
    columnDefs: columnDefs(
      protocolValue.resource,
      protocolValue.resource_template,
    ),
  };

  return <SearchSelectModal {...props} modalProps={modalProps} />;
};

export default ProtocolValueFormShowEditResourceItemOutput;
