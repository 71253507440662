import {
  dateOnlyAtUTC,
  format,
  generateNewDateString,
  humanReadableDate,
  humanReadableDateAtUTC,
  subtractDateDifference,
} from 'labstep-web/services/date.service';
import { Device } from '../device.model';
import { Molecule } from '../molecule.model';
import { OrderRequest } from '../order-request.model';
import {
  INPUT_OUTPUT_TYPES,
  INPUT_OUTPUT_TYPE_INPUT,
  INPUT_OUTPUT_TYPE_OUTPUT,
  Option,
} from '../protocol-value.model';
import { ResourceItem } from '../resource-item.model';
import { Resource } from '../resource.model';
import { Sequence, SequenceCreateBodyType } from '../sequence.model';
import { METADATA_FIELDS } from './constants';
import {
  IEntityWithMetadata,
  IMetadataCreateProps,
  IMetadataOptions,
  MetadataType,
} from './types';
import { Metadata } from '.';

export const getPrintValue = (metadata: Metadata): string | null => {
  if (metadata.protocol_device) {
    return metadata.device_data
      ? metadata.device_data.printValue
      : null;
  }

  if (metadata.type === MetadataType.date) {
    return metadata.date ? dateOnlyAtUTC(metadata.date) : null;
  }
  if (metadata.type === MetadataType.datetime) {
    return metadata.date ? humanReadableDate(metadata.date) : null;
  }
  if (metadata.type === MetadataType.numeric) {
    if (metadata.number && metadata.unit) {
      return `${metadata.number} ${metadata.unit}`;
    }
    if (metadata.number) {
      return `${metadata.number}`;
    }
    return null;
  }
  if (metadata.type === MetadataType.options) {
    return !metadata.options
      ? null
      : metadata.optionsSelectedValues.join(', ');
  }
  if (metadata.type === MetadataType.default) {
    return metadata.value;
  }
  if (metadata.type === MetadataType.sequence && metadata.sequence) {
    if (metadata.sequence.data) {
      return JSON.parse(metadata.sequence.data).sequence || null;
    }
  }

  return null;
};

export const getHasValue = (metadata: Metadata): boolean => {
  if (metadata.device_data) {
    if (metadata.type === MetadataType.numeric) {
      return (
        !!metadata.device_data.number || !!metadata.device_data.unit
      );
    }
    if (metadata.type === MetadataType.default) {
      return !!metadata.device_data.value;
    }
    if (metadata.type === MetadataType.file) {
      return metadata.device_data.files.length > 0;
    }
  }
  if (
    metadata.type === MetadataType.date ||
    metadata.type === MetadataType.datetime
  ) {
    return !!metadata.date;
  }
  if (metadata.type === MetadataType.molecule) {
    return !!(metadata.molecule && metadata.molecule.svg);
  }
  if (metadata.type === MetadataType.sequence) {
    return !!(
      metadata.sequence &&
      (JSON.parse(metadata.sequence.data).sequence ||
        metadata.sequence.name !== Sequence.createBodyDefault.name)
    );
  }
  if (metadata.type === MetadataType.numeric) {
    return metadata.number != null;
  }
  if (metadata.type === MetadataType.default) {
    return !!metadata.value;
  }
  if (metadata.type === MetadataType.file) {
    return metadata.files.length > 0;
  }
  if (metadata.type === MetadataType.options) {
    return metadata.optionsSelectedValues.length > 0;
  }
  return false;
};

export const getOptionsSelectedValues = (
  metadata: Metadata,
): string[] => {
  if (!metadata.options) {
    return [];
  }
  const { values } = metadata.options;
  let keys: string[] = [];
  if (metadata.optionsTemplate) {
    if (metadata.optionsTemplate.options) {
      keys = Object.keys(metadata.optionsTemplate.options.values);
    }
  } else {
    keys = Object.keys(metadata.options.values);
  }
  return Object.keys(values).filter(
    (key: string) => values[key] && keys.includes(key),
  );
};

export const getCanBeLinkedToDevice = (metadata: Metadata): boolean =>
  [
    MetadataType.default,
    MetadataType.file,
    MetadataType.numeric,
  ].indexOf(metadata.type) > -1;

export const getInputOutputTypeOption = (
  metadata: Metadata,
): Option => {
  if (metadata.is_output) {
    return INPUT_OUTPUT_TYPES.find(
      (option: Option) => option.value === INPUT_OUTPUT_TYPE_OUTPUT,
    ) as Option;
  }

  return INPUT_OUTPUT_TYPES.find(
    (option: Option) => option.value === INPUT_OUTPUT_TYPE_INPUT,
  ) as Option;
};

export const getHasAlertFired = (metadata: Metadata): boolean => {
  const currentTime = new Date(generateNewDateString()).getTime();
  return !!(
    metadata.date &&
    metadata.notification_alert &&
    subtractDateDifference(
      metadata.date,
      metadata.notification_alert.minutes_before,
    ).getTime() < currentTime
  );
};

export const getAlertTriggerTime = (
  metadata: Metadata,
): string | null => {
  if (!metadata.date || !metadata.notification_alert) {
    return null;
  }
  let text = 'Alert will fire on: ';
  const dateMinusMinutes = subtractDateDifference(
    metadata.date,
    metadata.notification_alert.minutes_before,
  );
  if (metadata.type === MetadataType.date) {
    text += `${humanReadableDateAtUTC(dateMinusMinutes)} (UTC)`;
  } else {
    text += humanReadableDate(format(dateMinusMinutes));
  }
  return text;
};

export const getEmptyOptions = (
  metadata: Metadata,
): IMetadataOptions => {
  const options: IMetadataOptions = {
    values: {},
    is_allow_add: metadata.options.is_allow_add,
    is_allow_multiple: metadata.options.is_allow_multiple,
  };
  Object.keys(metadata.options.values).forEach((option) => {
    options.values[option] = false;
  });
  return options;
};

export const getSequenceCreateBody = (
  metadata: Metadata,
): SequenceCreateBodyType | undefined => {
  if (metadata.type !== MetadataType.sequence) {
    return undefined;
  }
  return metadata.sequence
    ? metadata.sequence.createBody
    : Sequence.createBodyDefault;
};

export const getIsServingAsDefaultTemplate = (
  metadata: Metadata,
  entity: IEntityWithMetadata,
): boolean => {
  if (!metadata.is_template) {
    return false;
  }
  if (entity instanceof ResourceItem) {
    return !entity.is_template && !!entity.nonDeletedTemplate;
  }
  if (entity instanceof Resource || entity instanceof Device) {
    return !entity.is_template && !!entity.template;
  }
  if (entity instanceof OrderRequest) {
    return !entity.is_template;
  }

  return false;
};

export const getCreateProps = (
  metadata: Metadata,
  entity: IEntityWithMetadata,
): IMetadataCreateProps | undefined => {
  let props;
  if (metadata.getIsServingAsDefaultTemplate(entity)) {
    props = {
      createBody: {
        template_id: metadata.id,
      },
      parentName: entity.metadata_thread.entityName,
      parentId: entity.metadata_thread.id,
      type: metadata.type,
    } as IMetadataCreateProps;
    if (metadata.type === MetadataType.molecule) {
      props.createBody.molecule = Molecule.createBodyDefault;
      props.createBody.type = metadata.type;
    }
  }
  return props;
};

/** Get field name based on metadata type for get/update */
export const getMetadataFieldName = (
  type: Metadata['type'],
): string => METADATA_FIELDS[type].fields[0];
