/**
 * Labstep
 *
 * @module components/Protocol/Form/ShowEdit/State/Container/Edit
 * @desc State showedit edit container
 */

import React, { useCallback, useEffect, useState } from 'react';
import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { IProtocolFormShowEditStateContainerEditProps } from './types';

export const ProtocolFormShowEditStateContainerEdit: React.FC<
  IProtocolFormShowEditStateContainerEditProps
> = ({ entity, children }) => {
  const canEdit = useHasAccess(
    entity.entityName,
    entity.id,
    Action.edit,
  );

  const [editing, setEditing] = useState(
    canEdit && entity.hasEmptyState,
  );

  const setEditMode = useCallback((): void => {
    if (canEdit) {
      setEditing(true);
    }
  }, [canEdit]);

  const setViewMode = useCallback((): void => setEditing(false), []);

  useEffect(() => {
    if (!canEdit && editing) {
      setEditing(false);
    }
  }, [canEdit]);

  return children({ canEdit, editing, setEditMode, setViewMode });
};

export default ProtocolFormShowEditStateContainerEdit;
