/**
 * Labstep
 *
 * @module components/EntityImport/DataGrid
 * @desc EntityImport Data Grid
 */

import { GetContextMenuItemsParams } from 'ag-grid-community';
import { ICONS } from 'labstep-web/constants/icons';
import DataGrid from 'labstep-web/core/DataGrid';
import { useAgGridEntityImportServiceFromContext } from 'labstep-web/core/DataGrid/context';
import Icon from 'labstep-web/core/Icon';
import { AGGridEntityImportValidationService } from 'labstep-web/services/agGrid/ag-grid-entity-import-validation.service';
import {
  AGGridEntityImportService,
  DUPLICATE_IDENTIFIER_WARNING,
  EMPTY_IDENTIFIER_WARNING,
  INVALID_PREFIX_WARNING,
  RESOURCE_IDENTIFIER_COL_NAME,
} from 'labstep-web/services/agGrid/ag-grid-entity-import.service';
import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { EntityImportEntity } from 'labstep-web/models/entity-import.model';
import EntityImportActionAddRow from '../Action/AddRow';
import { EntityImportDataGridProps } from './types';

const getCustomIdentifierValidation = (
  columnDefs: EntityImportDataGridProps['columnDefs'],
  agGridEntityImportService?: AGGridEntityImportService,
  template?: EntityImportEntity,
): {
  isValid: boolean;
  errorMessage?: string;
} => {
  let isValid = true;
  let errorMessage: string | undefined;
  const colDef =
    columnDefs &&
    columnDefs.find(
      (col) => col.headerName === RESOURCE_IDENTIFIER_COL_NAME,
    );

  if (agGridEntityImportService && colDef) {
    const { hasEmptyIdentifiers, hasInvalidPrefix, hasDuplicates } =
      agGridEntityImportService.validateCustomIdentifiers(
        template,
        true,
      );

    const isResourceItem =
      'colId' in colDef &&
      colDef.colId === 'resource_item_resource_identifier';

    if (hasEmptyIdentifiers || hasInvalidPrefix || hasDuplicates) {
      isValid = false;

      if (!isResourceItem && hasDuplicates) {
        errorMessage = DUPLICATE_IDENTIFIER_WARNING;
      }
      if (hasInvalidPrefix) {
        errorMessage = INVALID_PREFIX_WARNING;
      }
      if (hasEmptyIdentifiers) {
        errorMessage = EMPTY_IDENTIFIER_WARNING;
      }
    }
  }
  return { isValid, errorMessage };
};

export const EntityImportDataGrid: React.FC<
  EntityImportDataGridProps
> = ({
  targetEntityName,
  template,
  columnDefs,
  rowData,
  showOnlyInvalidRows,
  setErrorMessages,
  setIsValid,
}) => {
  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();

  useEffect(() => {
    if (agGridEntityImportService) {
      agGridEntityImportService.onFilterChanged();
      const { isValid, errorMessage } = getCustomIdentifierValidation(
        columnDefs,
        agGridEntityImportService,
        template,
      );
      setIsValid(isValid);
      if (!isValid && errorMessage) {
        setErrorMessages([errorMessage]);
      } else {
        setErrorMessages([]);
      }
    }
  }, [agGridEntityImportService, columnDefs, showOnlyInvalidRows]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: () => <EntityImportActionAddRow />,
          align: 'left',
        },
      ],
    }),
    [],
  );

  return (
    <DataGrid
      height="100%"
      defaultColDef={AGGridEntityImportService.defaultColDef}
      columnDefs={columnDefs}
      rowData={rowData}
      context={{ template, targetEntityName }}
      suppressRowClickSelection
      isExternalFilterPresent={useCallback(
        (): boolean => showOnlyInvalidRows,
        [showOnlyInvalidRows],
      )}
      statusBar={statusBar}
      doesExternalFilterPass={useCallback(
        (node) => {
          const data = agGridEntityImportService?.getData();
          return AGGridEntityImportValidationService.isNodeInvalid(
            node,
            data,
            agGridEntityImportService?.getColumnDefs(),
            template,
          );
        },
        [
          showOnlyInvalidRows,
          agGridEntityImportService?.getColumnDefs(),
        ],
      )}
      getContextMenuItems={(event: GetContextMenuItemsParams) => [
        {
          name: 'Delete Row',
          icon: ReactDOMServer.renderToStaticMarkup(
            <Icon name={ICONS.ag_grid.delete_row} />,
          ),
          action: () => {
            if (event.api && event.node?.data) {
              event.api.applyTransaction({
                remove: [event.node.data],
              });
            }
          },
        },
        'separator',
        'copy',
        'separator',
        'export',
      ]}
      onCellValueChanged={(e) => {
        e.api?.refreshHeader();
        const { isValid, errorMessage } =
          getCustomIdentifierValidation(
            columnDefs,
            agGridEntityImportService,
            template,
          );
        setIsValid(isValid);
        if (!isValid && errorMessage) {
          setErrorMessages([errorMessage]);
        } else {
          setErrorMessages([]);
        }
      }}
      processDataFromClipboard={(params) => {
        return AGGridEntityImportService.addNewRowsOnPaste(params);
      }}
    />
  );
};

export default EntityImportDataGrid;
