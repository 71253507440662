/**
 * Labstep
 *
 * @module components/ProtocolValue/Action/QRScan
 * @desc ProtocolValue QR Scan Action
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import PermaLinkActionQRScan from 'labstep-web/components/PermaLink/Action/QRScan';
import ProtocolValuePermaLinkReader from 'labstep-web/components/ProtocolValue/PermaLinkReader';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import Modal from 'labstep-web/core/Modal/Default';
import React from 'react';
import { IProtocolValueActionQRScanProps } from './types';

export const ProtocolValueActionQRScan: React.FC<
  IProtocolValueActionQRScanProps
> = ({ protocolValue }) => (
  <Can
    entityName={protocolValue.entityName}
    id={protocolValue.idAttr}
    action={Action.protocol_value_edit}
  >
    <EntityUpdateContainer
      entityName={protocolValue.entityName}
      id={protocolValue.guid}
    >
      {({ update }) => (
        <Modal
          header="Scan Item"
          content={({ toggleModal }) => (
            <ProtocolValuePermaLinkReader
              update={update}
              toggleReader={toggleModal}
            />
          )}
          viewComponent={({ toggleModal }) => (
            <PermaLinkActionQRScan
              actionComponentProps={{
                text: 'Scan Item',
                onClick: toggleModal,
              }}
            />
          )}
        />
      )}
    </EntityUpdateContainer>
  </Can>
);

export default ProtocolValueActionQRScan;
