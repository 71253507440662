/**
 * Labstep
 *
 * @module screens/Group/Create
 * @desc Create a group form
 */

import React from 'react';
import { withRouter } from 'react-router';
import { navigation } from 'labstep-web/services/navigation';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import FormCreate from 'labstep-web/core/Form/Create';
import DocumentHead from 'labstep-web/core/DocumentHead';
import Header from 'labstep-web/core/Header';
import LayoutFullScreenPage from 'labstep-web/components/Layout/FullScreenPage';
import { fieldName } from 'labstep-web/components/Group/Form/fields';
import styles from './styles.module.scss';

const title = `Create new ${getHumanReadableEntityName('group')}`;

export const Center = ({ history, reload }) => {
  const onSuccess = ({ response }) => {
    history.push(
      navigation.get('group_show', { id: response.result }),
    );
    if (reload) {
      window.location.reload();
    }
  };
  return (
    <div className={styles.container}>
      <Header>{`Creating a new ${getHumanReadableEntityName(
        'group',
        false,
        true,
      )}...`}</Header>
      <div className={styles.formContainer}>
        <FormCreate
          entityName="group"
          parentName="user"
          autoFocus
          fields={[fieldName]}
          options={{ onSuccess }}
        />
      </div>
    </div>
  );
};

export const CreateGroup = (props) => (
  <>
    <DocumentHead title={title} />
    <LayoutFullScreenPage>
      <Center {...props} />
    </LayoutFullScreenPage>
  </>
);

export default withRouter(CreateGroup);
