import React from 'react';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import Header from 'labstep-web/core/Header';
import OrganizationHeader from 'labstep-web/components/Organization/Header';
import OrganizationEmptyStateNoSeatsLeft from 'labstep-web/components/Organization/EmptyState/NoSeatsLeft';
import { useHasExceededLimit } from 'labstep-web/hooks/limits/limits';
import { Group } from 'labstep-web/models/group.model';
import { LimitTypes } from 'labstep-web/constants/limits';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { IShareLinkGroupOrOrganizationProps } from './types';
import ShareLinkGroupOrOrganizationContent from './Content';
import { ShareLinkLimitReached } from './LimitReached';

export const ShareLinkGroupOrOrganization: React.FC<
  IShareLinkGroupOrOrganizationProps
> = ({ shareLink, onClick, status, authenticatedUserEntity }) => {
  const showUserLimitWarning = useHasExceededLimit(
    shareLink.entity instanceof Group
      ? shareLink.entity.user_count
      : -1,
    LimitTypes.MAX_USERS_PER_GROUP,
  );

  const showGroupLimitWarning = useHasExceededLimit(
    authenticatedUserEntity?.group_count,
    LimitTypes.MAX_GROUPS_PER_USER,
  );

  const entityDisplayName = getHumanReadableEntityName(
    shareLink.entity.entityName,
  );

  const renderContent = () => {
    switch (true) {
      case showGroupLimitWarning:
        return (
          <ShareLinkLimitReached
            heading={`
          You have reached your ${entityDisplayName} limit`}
            description={`
          You have already joined the maximum number of ${entityDisplayName} that an
          Academic user can join.`}
            onClick={onClick}
            showUpgrade
          />
        );

      case showUserLimitWarning:
        return (
          <ShareLinkLimitReached
            heading={`Invited ${entityDisplayName} full`}
            description={`
          The ${entityDisplayName} you were
          invited to has reached its user limit, so you cannot join.`}
            onClick={onClick}
          />
        );

      case shareLink.organization?.noSeatsLeft:
        return (
          <OrganizationEmptyStateNoSeatsLeft
            organization={shareLink.organization}
          />
        );

      default:
        return (
          <>
            {shareLink.organization && (
              <OrganizationHeader
                organization={shareLink.organization}
              />
            )}
            <Header size="small">
              {`You have been invited to join the ${
                shareLink.entity.displayName
              } ${getHumanReadableEntityName(
                shareLink.entity.entityName,
              )} on Labstep.`}
            </Header>
            <ShareLinkGroupOrOrganizationContent
              shareLink={shareLink}
              onClick={onClick}
              status={status}
            />
          </>
        );
    }
  };

  return (
    <div
      style={{
        textAlign: 'center',
        margin: 'auto',
        marginTop: '10%',
      }}
    >
      {renderContent()}
    </div>
  );
};

export default withAuthenticatedUserEntity(
  ShareLinkGroupOrOrganization,
);
