/**
 * Labstep
 *
 * @module components/Group/Action/Leave
 * @desc Leave group button
 */

import React from 'react';
import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import { navigation } from 'labstep-web/services/navigation';
import { withRouter } from 'react-router';
import { ILeaveGroupActionToggleContainerProps } from './types';

// Leave group
export const LeaveGroupActionToggleContainer: React.FC<
  ILeaveGroupActionToggleContainerProps
> = ({ group, userGroup, history, actionComponentProps }) =>
  group.canLeave ? (
    <EntityActionDelete
      entity={userGroup}
      header={`Leave ${getHumanReadableEntityName(group.entityName)}`}
      confirmMessage={`Are you sure you want to leave this ${getHumanReadableEntityName(
        group.entityName,
      )}? Leaving this ${getHumanReadableEntityName(
        'group',
      )} cannot be undone. To gain access
    to this ${getHumanReadableEntityName(
      group.entityName,
    )} again you will need to be re-invited.`}
      confirmButtonContent="Leave"
      options={{
        onSuccess: () => history.push(navigation.get('group_index')),
      }}
      actionComponentProps={{
        type: 'option',
        text: `Leave ${getHumanReadableEntityName(
          'group',
          false,
          true,
        )}`,
        icon: 'sign out',
        ...actionComponentProps,
      }}
    />
  ) : null;

export default withRouter(LeaveGroupActionToggleContainer);
