/**
 * Labstep
 *
 * @module components/Experiment/Action/End
 * @desc Action to finish experiment
 */

import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import styles from 'labstep-web/components/ProtocolStep/Card/Detail/styles.module.scss';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import ActionComponent from 'labstep-web/core/Action/Component';
import {
  IActionComponentButtonProps,
  IActionComponentIconProps,
} from 'labstep-web/core/Action/Component/types';
import {
  generateNewDateString,
  humanReadableDate,
} from 'labstep-web/services/date.service';
import React from 'react';
import {
  IExperimentActionEndProps,
  IExperimentActionEndViewComponentProps,
} from './types';

export const ExperimentActionEndViewComponent: React.FC<
  IExperimentActionEndViewComponentProps
> = ({
  experiment,
  actionType = 'button',
  disabled,
  status,
  onClick,
}) => {
  let actionComponentProps = {};
  switch (actionType) {
    case 'icon':
      actionComponentProps = {
        type: 'icon',
        elementProps: {
          name: 'check circle',
          color: 'yellow',
          size: 'large',
          popup: {
            content: (
              <div>
                <div>{`Started on: ${humanReadableDate(
                  experiment.started_at,
                )}`}</div>
                <div>{`Progress: ${experiment.completionPercentage} steps completed`}</div>
                <div>Click to Mark as Complete</div>
              </div>
            ),
          },
          className: styles.actionButton,
        },
      } as IActionComponentIconProps;
      break;
    case 'button':
    default:
      actionComponentProps = {
        type: 'button',
        text: 'Mark as Complete',
      } as IActionComponentButtonProps;
  }
  return (
    <ActionComponent
      type={actionType}
      status={status}
      disabled={disabled}
      onClick={onClick}
      {...actionComponentProps}
    />
  );
};

export const ExperimentActionEnd: React.FC<
  IExperimentActionEndProps
> = ({
  experiment,
  options = {},
  actionType = 'button',
  disabled,
}) => (
  <Can
    entityName={experiment.entityName}
    id={experiment.id}
    action={Action.edit}
  >
    <EntityUpdateContainer
      entityName={experiment.entityName}
      id={experiment.id}
    >
      {({ update, status }) => (
        <ExperimentActionEndViewComponent
          experiment={experiment}
          actionType={actionType}
          onClick={() => {
            update({ ended_at: generateNewDateString() }, options);
          }}
          status={status}
          disabled={disabled}
        />
      )}
    </EntityUpdateContainer>
  </Can>
);
export default ExperimentActionEnd;
