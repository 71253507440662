/**
 * Labstep
 *
 * @desc Component to select workspace
 */

import React from 'react';
import { ShowEditToggleComponent } from 'labstep-web/core/Form/ShowEdit';
import Icon from 'labstep-web/core/Icon';
import GroupSearchSelect from 'labstep-web/components/Group/SearchSelect';
import { ILayoutGlobalSearchGroupsSelectorProps } from './types';
import styles from './styles.module.scss';

const LayoutGlobalSearchGroupsSelector: React.FC<
  ILayoutGlobalSearchGroupsSelectorProps
> = ({ searchParams, setParams }) => (
  <div className={styles.container}>
    <ShowEditToggleComponent
      canEdit
      hasValue
      name="Workspace"
      toggledComponent={({ toggle }) => (
        <GroupSearchSelect
          setOptions={(options) => [
            { name: 'All Workspaces', id: 0 },
            ...options,
          ]}
          autoFocus
          value={searchParams.group}
          onBlur={toggle}
          onChange={(group) => {
            setParams({ group: group.id === 0 ? undefined : group });
            toggle();
          }}
        />
      )}
    >
      <span className={styles.view}>
        {searchParams.group
          ? searchParams.group.name
          : 'All Workspaces'}{' '}
        <Icon name="chevron down" />
      </span>
    </ShowEditToggleComponent>
  </div>
);

export default LayoutGlobalSearchGroupsSelector;
